export default {
  pages: [
    {
      speech: [
        {
          text: '<p>The future is ruled by an evil authority who exploit the resources of nature for their own interest.</p>',
          style: {
            top: '15%',
            left: '2.5rem',
          }
        },
        {
          text: '<p>Professor G is part of the revolutionists who fought against this authority and currently being hunted by them.</p>',
          style: {
            right: '2.5rem',
            bottom: '30%',
          }
        },
      ],
      panels: 3,
    },
    {
      speech: [
        {
          text: '<p>Meanwhile in the present time... Tama is a highschool student who is on a verge of death.</p>',
          style: {
            top: '8%',
            left: '2rem',
          }
        },
        {
          text: '<p>He suddenly got a visit from Professor G, who travels back in time and is on a mission to cures Tama with help from a power stone.</p>',
          style: {
            right: '2.5rem',
            top: '30%',
          }
        },
        {
          text: '<p>The stone infuse the power of Tama\'s pet, a Red Weevil, into him and so Tama becomes <b>KALAWIRA</b>.</p>',
          style: {
            right: '30%',
            bottom: '1rem',
          }
        },
      ],
      panels: 4
    },
    {
      speech: [
        {
          text: '<p>Turns out Professor G is not the only person who travels back in time!</p>',
          style: {
            top: '17%',
            left: '3rem',
          }
        },
        {
          text: '<p>General Leosura is coming to hunt Professor G.</p>',
          style: {
            top: '30%',
            right: '2.5rem',
          }
        },
        {
          text: '<p>Tama as Kalawira tries to face him off and nearly lose his life.</p>',
          style: {
            bottom: '10%',
            left: '30%',
          }
        },
      ],
      panels: 5
    },
    {
      speech: [
        {
          text: '<p>Luckily, Profesor G come to the rescue.</p>',
          style: {
            top: '20%',
            left: '2rem',
          }
        },
        {
          text: '<p>Using a modified helmet, he successfully led Kalawira to safety but it costs him his own life.</p>',
          style: {
            bottom: '25%',
            right: '25%',
          }
        },
      ],
      panels: 5
    },
    {
      speech: [
        {
          text: 'With the power of ‘Time Manipulation’ from Professor\'s G modified helmet, Kalawira manage to beat General Leosura and destroys his power stone.',
          style: {
            top: '30%',
            left: '10%',
          }
        },
      ],
      panels: 5
    },
    {
      speech: [
        {
          text: '<p>The stone shattered into thousands of pieces and spreads all around the world.</p>',
          style: {
            top: '10%',
            right: '10%',
          }
        },
        {
          text: '<p>Each person who found a piece of the stone will be bestowed the power of the nature around them.</p>',
          style: {
            left: '10%',
            bottom: '10%',
          }
        },
      ],
      panels: 2
    },
  ]
}

<template>
  <div id="home" class="section">
    <div ref="heroWrap" class="hero-wrap">
      <div ref="heroBuildings" class="hero-part buildings" />
      <div ref="heroGraffiti" class="hero-part graffiti" />

      <div v-for="n in 6" :key="n" :ref="`heroChar${n}`" :class="['hero-part hero-char', `char${n}`]" />

      <div ref="heroSpeed" class="hero-part speed" />
      <div ref="heroFlare" class="hero-part flare" />
      <div ref="heroLighting" class="hero-part lighting" />
    </div>

    <div class="flex items-end justify-center h-full z-10">
      <div class="text-center pb-12">
        <minter />
      </div>
    </div>
  </div>
</template>

<script>
import Rellax from 'rellax'
import { forEach } from 'lodash'
import Minter from '@/components/Minter'

export default {
  name: 'Home',
  components: {
    Minter
  },
  data() {
    return {
      charsParallax: false,
      buildingsParallax: false,
      graffitiParallax: false,
    }
  },
  mounted() {
    setTimeout(() => {
      this.setState({
        name: 'loading',
        value: false,
      })
      this.$refs.heroGraffiti.classList.add('init')
    }, 500)

    setTimeout(() => {
      const chars = document.getElementsByClassName('hero-char')
      forEach(chars, (el) => {
        el.classList.add('init')
      })
      this.$refs.heroFlare.classList.add('init')
    }, 1000)

    // Parallax characters
    this.charsParallax = new Rellax('.hero-char', {
      speed: -1
    })

    // Parallax buildings
    this.buildingsParallax = new Rellax('.hero-part.buildings', {
      speed: -6
    })

    // Parallax graffiti
    this.graffitiParallax = new Rellax('.hero-part.graffiti', {
      speed: -3
    })

    window.addEventListener('resize', this.refreshParallax)
  },
  beforeMount() {
    window.removeEventListener('resize', this.refreshParallax)
  },
  methods: {
    refreshParallax() {
      this.charsParallax.refresh()
      this.buildingsParallax.refresh()
      this.graffitiParallax.refresh()
    },
    heroParallax() {
      const wrap = this.$refs.heroWrap
      const scrollPosition = window.pageYOffset
      const buildings = this.$refs.heroBuildings
      const graffiti = this.$refs.heroGraffiti
      const flare = this.$refs.heroFlare
      const limit = wrap.offsetTop + wrap.offsetHeight; 
      if (scrollPosition > wrap.offsetTop && scrollPosition <= limit){
        buildings.style.backgroundPositionY = (50 - 20 * scrollPosition / limit) + '%'
        graffiti.style.backgroundPositionY = (60 + 50 * scrollPosition / limit) + '%'
        flare.style.backgroundPositionY = (20 + 30 * scrollPosition / limit) + '%'
      } else {
        buildings.style.backgroundPositionY = '50%'
        graffiti.style.backgroundPositionY = '60%'
        flare.style.backgroundPositionY = '20%'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$charsDesktop: (
  1: ( left: 25%, top: -13%, width: 21%, preWidth: 12%, preLeft: 42%, preTop: 65% ), // top left
  2: ( left: 78%, top: 15%, width: 25%, preWidth: 12%, preLeft: 55%, preTop: 70% ), // top right
  3: ( left: 50%, top: 95%, width: 23%, preWidth: 12%, preLeft: 50%, preTop: 100% ), // bottom center
  4: ( left: 15%, top: 100%, width: 28%, preWidth: 15%, preLeft: 40%, preTop: 100% ), // bottom left
  5: ( left: 80%, top: 125%, width: 20%, preWidth: 10%, preLeft: 60%, preTop: 95% ), // bottom right
  6: ( left: 50%, top: 15%, width: 33%, preWidth: 20%, preLeft: 50%, preTop: 80% ), // top center
);

$charsMobile: (
  1: ( left: 5%, top: 22%, width: 44%, preWidth: 18%, preLeft: 42%, preTop: 85% ), // top left
  2: ( left: 95%, top: 28%, width: 50%, preWidth: 20%, preLeft: 55%, preTop: 90% ), // top right
  3: ( left: 50%, top: 95%, width: 44%, preWidth: 15%, preLeft: 50%, preTop: 100% ), // bottom center
  4: ( left: -40%, top: 110%, width: 63%, preWidth: 20%, preLeft: 40%, preTop: 100% ), // bottom left
  5: ( left: 110%, top: 110%, width: 43%, preWidth: 15%, preLeft: 60%, preTop: 105% ), // bottom right
  6: ( left: 52%, top: 57%, width: 85%, preWidth: 30%, preLeft: 50%, preTop: 95% ), // top center
);

#home {
  @apply relative w-full h-screen overflow-hidden;

  .hero-wrap {
    @apply absolute w-full h-full top-0 left-0 pointer-events-none;
    transition: transform 0.1s linear;
  }

  .hero-part {
    @apply absolute inset-0 bg-no-repeat pointer-events-none;
    z-index: -1;

    &.buildings {
      will-change: background-position-y;
      background-image: url('~@/assets/img/hero/buildings.jpg');
      background-position: 50% 50%;
    }

    &.graffiti {
      will-change: background-size, opacity, background-position-y;
      background-image: url('~@/assets/img/hero/graffiti.png');
      background-size: 30% auto;
      opacity: 0;
      background-position: 60% 60%;
      transition: background-size 0.5s ease-out, opacity 0.5s ease-out;

      &.init {
        background-size: 90% auto;
        opacity: 1;
      }

      @screen md {
        &.init {
          background-size: 80% auto;
        }
      }
    }

    &.flare {
      will-change: transform, background-position-y;
      background-image: url('~@/assets/img/hero/flare.png');
      background-position: 135% 20%;
      transition: transform 0.6s cubic-bezier(0.76, 0, 0.24, 1);
      transform: scale(0);

      &.init {
        transform: scale(1);
      }
    }

    &.lighting {
      background-image: url('~@/assets/img/hero/lighting.png');
      background-size: 100% 100%;
      background-position: left bottom;
    }

    &.speed {
      background-image: url('~@/assets/img/hero/speed.png');
      background-position: center bottom;
    }

    @each $i, $args in $charsMobile {
      &.char#{$i} {
        will-change: opacity, background-size, background-position, transform;
        opacity: 0;
        transition:
          opacity 0.6s cubic-bezier(0.76, 0, 0.24, 1),
          background-size 0.6s cubic-bezier(0.76, 0, 0.24, 1),
          background-position 0.6s cubic-bezier(0.76, 0, 0.24, 1);
        background-image: url('~@/assets/img/hero/new-chars/char#{$i}.png');

        background-size: map-get($args, 'preWidth') auto;
        background-position: map-get($args, 'preLeft') map-get($args, 'preTop');

        &.init {
          opacity: 1;
          background-size: map-get($args, 'width') auto;
          background-position: map-get($args, 'left') map-get($args, 'top');
        }
      }
    }

    @screen md {
      @each $i, $args in $charsDesktop {
        &.char#{$i} {
          background-size: map-get($args, 'preWidth') auto;
          background-position: map-get($args, 'preLeft') map-get($args, 'preTop');
  
          &.init {
            opacity: 1;
            background-size: map-get($args, 'width') auto;
            background-position: map-get($args, 'left') map-get($args, 'top');
          }
        }
      }
    }
  }
}
</style>

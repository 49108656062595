<template>
  <div id="rarities" class="section">
    <div class="container py-12">
      <div class="content">
        <h1 class="title">FIND YOUR CLAN</h1>
        <p>Integer eget elit id mauris posuere sollicitudin. Etiam vestibulum accumsan dui quis mattis. Ut consectetur vel dui eu laoreet. Proin eget massa pulvinar, maximus felis molestie, dictum lorem.</p>
        <p>Suspendisse consectetur diam id dolor consectetur pulvinar. Integer nec sapien suscipit, mattis mauris in, convallis nunc.</p>
      </div>
    </div>
    <div v-for="(type, index) in types" :key="index" class="type" :style="{ backgroundColor: type.background }">
      <div class="container">
        <div class="type__name" v-html="type.name" />
        <div class="type__rarity" v-html="type.rarity" />
        <div class="type__description mb-6" v-html="type.description" />
        <div class="type__traits">
          <div v-for="(trait, index) in type.traits" :key="index" class="type__trait">
            <div class="type__trait-image">
              <img :src="require(`@/assets/img/${trait.image}`)">
            </div>
            <div class="type__trait-title" v-html="trait.title" />
            <div class="type__trait-description" v-html="trait.description" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { types } from '../rarities'

export default {
  name: 'Rarities',
  data() {
    return {
      types
    }
  }
}
</script>

<style lang="scss" scoped>
#rarities {
  background-color: #0c6b82;
}

.title {
  @apply text-4xl font-bold mb-6;
  text-shadow: -3px 3px 0 #b73030;
}

.type {
  @apply py-12;

  &__name {
    @apply text-2xl font-bold;
  }

  &__rarity {
    @apply text-xl mb-6;
  }

  &__traits {
    @apply flex flex-wrap -mx-8;
  }

  &__trait {
    @apply w-full lg:w-1/3 px-8 mb-12;

    &-image {
      @apply bg-white mb-4;
    }

    &-title {
      @apply font-bold mb-2 text-lg;
    }
  }
}
</style>

export const types = [
  {
    name: 'TYPE: ABCD',
    rarity: 'Common',
    background: '#095669',
    description: '<p>Donec placerat neque a elit ornare vulputate. Praesent dapibus erat vel auctor euismod. Sed lacinia augue a elit ornare lacinia. Fusce vel hendrerit nisl. Vestibulum tristique ipsum a elit efficitur, eleifend dictum tortor tristique. Nullam mi ipsum, semper in mollis vitae, commodo vitae dui.</p>',
    traits: [
      {
        image: 'new-team/team-1.png',
        title: 'Population: 5,278 (68%)',
        description: 'Praesent dapibus erat vel auctor euismod. Sed lacinia augue a elit ornare lacinia. Fusce vel hendrerit nisl.',
      },
      {
        image: 'new-team/team-2.png',
        title: 'Common Traits',
        description: 'Praesent dapibus erat vel auctor euismod. Sed lacinia augue a elit ornare lacinia. Fusce vel hendrerit nisl.',
      },
      {
        image: 'new-team/team-3.png',
        title: 'Rare Traits',
        description: 'Praesent dapibus erat vel auctor euismod. Sed lacinia augue a elit ornare lacinia. Fusce vel hendrerit nisl.',
      },
    ],
  },
  {
    name: 'TYPE: EFGH',
    rarity: 'Uncommon',
    background: '#064352',
    description: '<p>Phasellus consectetur nisl id orci blandit ultrices. Donec maximus libero id lacus blandit porta et nec elit. Cras id venenatis est. Cras vitae sem non felis fermentum consequat et vel nisi.</p>',
    traits: [
      {
        image: 'new-team/team-1.png',
        title: 'Population: 2,100 (27%)',
        description: 'Praesent dapibus erat vel auctor euismod. Sed lacinia augue a elit ornare lacinia. Fusce vel hendrerit nisl.',
      },
      {
        image: 'new-team/team-2.png',
        title: 'Common Traits',
        description: 'Praesent dapibus erat vel auctor euismod. Sed lacinia augue a elit ornare lacinia. Fusce vel hendrerit nisl.',
      },
      {
        image: 'new-team/team-3.png',
        title: 'Rare Traits',
        description: 'Praesent dapibus erat vel auctor euismod. Sed lacinia augue a elit ornare lacinia. Fusce vel hendrerit nisl.',
      },
    ],
  },
  {
    name: 'TYPE: IJKL',
    rarity: 'Rare',
    background: '#032e38',
    description: '<p>Morbi a neque rhoncus, faucibus neque id, venenatis enim. Sed vulputate sapien non odio pretium, sit amet sagittis felis porttitor. Curabitur viverra malesuada tristique. Nunc dignissim justo at arcu fermentum ornare.</p>',
    traits: [
      {
        image: 'new-team/team-4.png',
        title: 'Population: 392 (5%)',
        description: 'Praesent dapibus erat vel auctor euismod. Sed lacinia augue a elit ornare lacinia. Fusce vel hendrerit nisl.',
      },
      {
        image: 'new-team/team-5.png',
        title: 'Common Traits',
        description: 'Praesent dapibus erat vel auctor euismod. Sed lacinia augue a elit ornare lacinia. Fusce vel hendrerit nisl.',
      },
      {
        image: 'new-team/team-6.png',
        title: 'Rare Traits',
        description: 'Praesent dapibus erat vel auctor euismod. Sed lacinia augue a elit ornare lacinia. Fusce vel hendrerit nisl.',
      },
    ],
  },
]

<template>
  <div id="about" class="section">
    <div class="container">
      <div class="about-content">
        <div class="w-full lg:w-1/2">
          <h1 class="text-4xl font-bold mb-12 text-center">
            <img :src="require('@/assets/img/kalawira-logo-black-red.png')" class="w-80 max-w-full mx-auto">
          </h1>
          <div class="content text-center">
            <p class="text-lg">
              The voodoo sacerdos flesh eater, suscitat mortuos comedere carnem virus. Zonbi tattered for solum oculi eorum defunctis go lum cerebro. Nescio brains an Undead zombies. Sicut malus putrid voodoo horror. Nigh tofth eliv ingdead.
            </p>
            <p class="text-lg">
              De apocalypsi gorger omero undead survivor dictum mauris. Hi mindless mortuis soulless creaturas, imo evil stalking monstra adventus resi dentevil vultus comedat cerebella viventium. Qui animated corpse, cricket bat max brucks terribilem incessu zomby.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="about-image">
      <img :src="require('@/assets/img/about-image.png')">
    </div>
  </div>
</template>

<script>
import Rellax from 'rellax'

export default {
  name: 'About',
  data() {
    return {
      parallax: false
    }
  },
  mounted() {
    this.parallax = new Rellax('.about-image>img', {
      speed: 1.5
    })

    window.addEventListener('resize', this.refreshParallax)
  },
  beforeMount() {
    window.removeEventListener('resize', this.refreshParallax)
  },
  methods: {
    refreshParallax() {
      this.parallax.refresh()
    }
  }
}
</script>

<style scoped lang="scss">
#about {
  @apply overflow-hidden;
  background-color: #111;

  h1 {
    @apply text-center text-3xl font-bold uppercase mb-12;
  }
}

.about-content {
  padding-top: 15vh;
  padding-bottom: 15vh;

  @screen lg {
    padding-top: 20vh;
    padding-bottom: 20vh;
  }
}

.about-image {
  @apply relative h-72 lg:h-32;
  background-color: #b73030;

  &::before {
    @apply absolute h-28 -top-12;
    width: 105%;
    content: '';
    background-color: #b73030;
    transform: rotate(2deg);
  }

  img {
    @apply absolute w-full right-0;
    bottom: -35vh;

    @screen lg {
      width: 36vw;
      bottom: -30vh;
    }
  }
}
</style>

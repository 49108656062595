<template>
    <div id="faqs" class="section">
      <div class="faq-title">Frequently Asked Questions</div>
      <div class="container">
        <div class="flex flex-col items-center py-12 lg:py-20">
          <div v-for="(faq, index) in faqs" :key="index" class="faq" @click="toggleDropdown(index)">
            <div class="question">
              <span v-html="faq.question" />
              <div class="toggle">
                <unicon :name="faq.open ? 'minus-circle' : 'plus-circle'" width="1.5rem" height="1.5rem" fill="currentColor" />
              </div>
            </div>
            <div v-if="faq.open" class="answer" v-html="faq.answer" />
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'FAQs',
  data() {
    return {
      faqs: [
        {
          question: 'Question 1',
          answer: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Doloribus autem explicabo hic voluptate eius unde quae expedita deleniti quis. Quibusdam sequi aperiam iusto placeat iste tempora consequuntur, facere voluptatibus molestiae.',
          open: false
        },
        {
          question: 'Question 2',
          answer: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Doloribus autem explicabo hic voluptate eius unde quae expedita deleniti quis. Quibusdam sequi aperiam iusto placeat iste tempora consequuntur, facere voluptatibus molestiae.',
          open: false
        },
        {
          question: 'Question 3',
          answer: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Doloribus autem explicabo hic voluptate eius unde quae expedita deleniti quis. Quibusdam sequi aperiam iusto placeat iste tempora consequuntur, facere voluptatibus molestiae.',
          open: false
        },
        {
          question: 'Question 4',
          answer: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Doloribus autem explicabo hic voluptate eius unde quae expedita deleniti quis. Quibusdam sequi aperiam iusto placeat iste tempora consequuntur, facere voluptatibus molestiae.',
          open: false
        },
        {
          question: 'Question 5',
          answer: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Doloribus autem explicabo hic voluptate eius unde quae expedita deleniti quis. Quibusdam sequi aperiam iusto placeat iste tempora consequuntur, facere voluptatibus molestiae.',
          open: false
        },
        {
          question: 'Question 6',
          answer: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Doloribus autem explicabo hic voluptate eius unde quae expedita deleniti quis. Quibusdam sequi aperiam iusto placeat iste tempora consequuntur, facere voluptatibus molestiae.',
          open: false
        },
      ]
    }
  },
  methods: {
    toggleDropdown(index) {
      this.faqs[index].open = !this.faqs[index].open
    }
  }
}
</script>

<style scoped lang="scss">
#faqs {
  @apply w-full;
  background-color: #1c3e3f;
}

.faq-title {
  @apply text-center py-16 px-4 text-3xl font-bold;
  background-color: #b73030;
}

.faq {
  @apply cursor-pointer py-2 mx-4 w-full border-b-2 border-gray-400;

  .question {
    @apply text-xl font-bold flex items-center justify-between;
  }

  .toggle {
    @apply flex items-center justify-center w-8 h-8;
  }
  
  .answer {
    @apply py-3;
  }
}
</style>

import { createApp } from 'vue'
import App from './App.vue'
import store from './store'

const app = createApp(App)

// Unicons
import Unicon from 'vue-unicons'
import { uniTwitter, uniDiscord, uniPlusCircle, uniMinusCircle, uniExternalLinkAlt } from 'vue-unicons/dist/icons'
Unicon.add([uniTwitter, uniDiscord, uniPlusCircle, uniMinusCircle, uniExternalLinkAlt])
app.use(Unicon)

// Smooth Scroll
import VueSmoothScroll from 'vue3-smooth-scroll'
app.use(VueSmoothScroll)

// Teleport
import 'vue-universal-modal/dist/index.css'
import VueUniversalModal from 'vue-universal-modal'
app.use(VueUniversalModal, {
  teleportTarget: '#modals'
})

// Mixins
import mixins from './mixins'
app.mixin(mixins)

app.use(store)
app.mount('#app')

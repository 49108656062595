import { mapGetters, mapActions } from 'vuex'

export default {
  data() {
    return {
      logo: require('@/assets/img/kalawira-logo.png'),
      activeNav: 'home',
      navs: [
        {
          id: 'home',
          label: 'Home',
        },
        {
          id: 'about',
          label: 'About',
        },
        {
          id: 'rarities',
          label: 'Rarities',
        },
        {
          id: 'roadmap',
          label: 'Roadmap',
        },
        {
          id: 'team',
          label: 'Team',
        },
        {
          id: 'faqs',
          label: 'FAQs',
        },
      ]
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'comic',
      'address',
      'contract',
      'alert',
      'web3',
      'provider',
      'cachedWallet',
      'walletModal'
    ])
  },
  methods: {
    ...mapActions([
      'connectWallet',
      'disconnectWallet',
      'setAddress',
      'setAlert',
      'setState',
      'initContract',
      'getContractData',
      'purchase',
      'purchaseAllowList',
      'onAllowList',
      'allowListClaimedBy',
      'publicClaimedBy',
      'getBalanceOf',
    ]),
    resetScroll() {
      document.body.scrollTop = 0 // For Safari
      document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
    },
    slideTo(sectionId) {
      const el = document.getElementById(sectionId)

      this.$smoothScroll({
        scrollTo: el,
        hash: `#${sectionId}`,
        updateHistory: false,
        duration: 1500,
        offset: 0,
        easingFunction: t => t<.5 ? 16*t*t*t*t*t : 1+16*(--t)*t*t*t*t
      })
    }
  }
}

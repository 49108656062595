<template>
  <div ref="alert" class="alert">
    <div class="alert__content">
      <div ref="character" class="alert__character">
        <img :src="require('@/assets/img/dialog-box/chara-1.png')">
      </div>
      <div ref="bubble" class="alert__bubble">
        <div class="alert__speech">
          <div class="font-comic mb-4">{{ message }}</div>
          <button type="button" class="focus:outline-none text-sm text-red-600" @click="closeAlert">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Alert',
  data() {
    return {
      message: null
    }
  },
  watch: {
    alert(show) {
      if (show) {
        this.message = this.alert
        this.$refs.alert.classList.add('show')
        setTimeout(() => {
          this.$refs.character.classList.add('show')
        }, 300)
        setTimeout(() => {
          this.$refs.bubble.classList.add('show')
        }, 500)
      } else {
        this.$refs.bubble.classList.remove('show')
        setTimeout(() => {
          this.$refs.character.classList.remove('show')
        }, 200)
        setTimeout(() => {
          this.$refs.alert.classList.remove('show')
          this.message = null
        }, 500)
      }
    }
  },
  methods: {
    closeAlert() {
      this.setState({
        name: 'alert',
        value: null
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.alert {
  @apply fixed inset-0 bg-black bg-opacity-40 z-50 opacity-0 pointer-events-none transition-opacity duration-300;

  &.show {
    @apply opacity-100 pointer-events-auto;
  }

  &__content {
    @apply absolute left-0 bottom-0 max-w-full w-auto px-4 md:px-8 flex items-start;
  }

  &__character {
    @apply self-end;
    max-width: 140px;
    transform: translateY(100%);
    transition: transform .5s cubic-bezier(0.85, 0, 0.15, 1);

    @screen md {
      max-width: 140px;
    }

    &.show {
      transform: translateY(0);
    }
  }

  &__bubble {
    @apply pl-4 mb-20;
    transform: scale(0);
    transition: transform .5s cubic-bezier(0.85, 0, 0.15, 1);
    transform-origin: bottom left;

    &.show {
      transform: scale(1);
    }
  }

  &__speech {
    @apply relative bg-white text-black text-center max-w-sm rounded-3xl p-4 h-auto border-2 border-black;

    &::before {
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
      left: 20px;
      right: auto;
      top: auto;
      bottom: -26px;
      border: 12px solid;
      border-color: black transparent transparent black;
    }

    &::after{
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
      left: 23px;
      right: auto;
      top: auto;
      bottom: -20px;
      border: 12px solid;
      border-color: white transparent transparent white;
    }
  }
}
</style>

<template>
  <button type="button" class="wallet-connector" @click="connect">
    {{ address ? shortAddress : 'Connect Wallet' }}
  </button>
</template>

<script>
export default {
  name: 'WalletConnector',
  computed:{
    shortAddress() {
      if (!this.address)
        return false

      const start = this.address.substring(0, 6)
      const end = this.address.substring(this.address.length - 4, this.address.length)
      return start + '...' + end
    }
  },
  methods: {
    connect() {
      this.setState({
        name: 'walletModal',
        value: true
      })
    }
  }
}
</script>

<style scoped lang="scss">
.wallet-connector {
  @apply bg-red-600 hover:bg-red-700 cursor-pointer rounded-full py-2 px-4 text-sm focus:outline-none flex items-center;
}
</style>

import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import Web3 from 'web3'
import WalletConnectProvider from '@walletconnect/web3-provider'
import Kalawira from '../assets/contracts/KalawiraTest.json'

export default createStore({
  state: {
    loading: true,
    comic: true,
    alert: null,
    web3: undefined,
    provider: undefined,
    cachedWallet: null,
    address: null,
    contract: null,
    walletModal: false,
  },
  getters: {
    loading: state => state.loading,
    comic: state => state.comic,
    alert: state => state.alert,
    web3: state => state.web3,
    provider: state => state.provider,
    cachedWallet: state => state.cachedWallet,
    address: state => state.address,
    contract: state => state.contract,
    walletModal: state => state.walletModal,
  },
  mutations: {
    loading(state, loading) {
      state.loading = loading
    },
    comic(state, show) {
      state.comic = show
    },
    alert(state, alert) {
      state.alert = alert
    },
    web3(state, web3) {
      state.web3 = web3
    },
    provider(state, provider) {
      state.provider = provider
    },
    cachedWallet(state, cachedWallet) {
      state.cachedWallet = cachedWallet
    },
    address(state, address) {
      state.address = address
    },
    contract(state, contract) {
      state.contract = contract
    },
    walletModal(state, walletModal) {
      state.walletModal = walletModal
    }
  },
  actions: {
    resetConnection({ commit }) {
      commit('web3', undefined)
      commit('provider', undefined)
      commit('cachedWallet', null)
      commit('address', null)
      commit('contract', null)
    },
    connectWallet({ dispatch }, walletName) {
      if (walletName === 'metamask')
        dispatch('connectMetaMask')
      else if (walletName === 'walletconnect')
        dispatch('connectWalletConnect')
    },
    disconnectWallet({ dispatch, state }) {
      if (state.cachedWallet === 'metamask')
        dispatch('disconnectMetaMask')
      else if (state.cachedWallet === 'walletconnect')
        dispatch('disconnectWalletConnect')
    },
    async connectMetaMask({ commit, dispatch }) {
      if (!window.ethereum) {
        window.alert("You don't have MetaMask installed on your browser!")
        return false
      }
      console.log('connect metamask')
      const web3 = new Web3(window.ethereum)
      commit('web3', web3)
      commit('cachedWallet', 'metamask')
      const addresses = await window.ethereum.request({ method: 'eth_requestAccounts' })
      commit('address', addresses[0])
      await dispatch('initContract')
      dispatch('mmEvents')
    },
    async disconnectMetaMask({ dispatch }) {
      dispatch('resetConnection')
    },
    async mmEvents({ commit, dispatch }) {
      window.ethereum.on('accountsChanged', (accounts) => {
        if (accounts.length) {
          commit('address', accounts[0])
        } else {
          dispatch('resetConnection')
        }
      })

      window.ethereum.on('chainChanged', () => {
        dispatch('initContract')
      })

      window.ethereum.on('disconnect', (error) => {
        console.log(error)
      })
    },
    async connectWalletConnect({ commit, dispatch, state }) {
      //  Create WalletConnect Provider
      const provider = new WalletConnectProvider({
        infuraId: "7950ebd555374a918221ed0a1fdf0376",
      })

      commit('provider', provider)
      await state.provider.enable()

      const web3 = new Web3(state.provider)
      commit('web3', web3)

      const addresses = await web3.eth.getAccounts()
      commit('address', addresses[0])

      await dispatch('initContract')

      commit('cachedWallet', 'walletconnect')

      await dispatch('wcEvents')
    },
    async disconnectWalletConnect({ dispatch, state }) {
      await state.provider.disconnect()
      dispatch('resetConnection')
    },
    async wcEvents({ dispatch, state }) {
      // Subscribe to accounts change
      state.provider.on("accountsChanged", (addresses) => {
        console.log(addresses)
        dispatch('address', addresses[0])
      })

      // Subscribe to chainId change
      state.provider.on("chainChanged", (chainId) => {
        console.log(chainId)
        dispatch('initContract')
      })

      // Subscribe to session disconnection
      state.provider.on("disconnect", (code, reason) => {
        console.log(code, reason)
        dispatch('resetConnection')
      })
    },
    handleAccountChanged(accounts) {
      console.log(accounts)
    },
    handleChainChanged(chainId) {
      console.log(chainId)
    },
    handleDisconnect(info) {
      console.log(info)
    },
    async initContract({ commit, dispatch, state }) {
      console.log('init contract')
      const id = await state.web3.eth.net.getId()
      if (Kalawira.addresses[id] !== undefined) {
        const contract = new state.web3.eth.Contract(
          Kalawira.abi,
          Kalawira.addresses[id]
        )
        commit('contract', contract)
      } else {
        commit('contract', null)
        dispatch('setAlert', "Smart contract doesn't exists on current active network!")
      }
      return state.contract
    },
    async getContractData({ state }, payload) {
      const contract = state.contract
      if (!contract)
        return false

      const result = await contract.methods[payload].call().call()
      return result
    },
    async purchase({ state }, numberOfTokens) {
      if (!state.address) {
        return false
      } else {
        const contract = state.contract
        const price = await contract.methods.tokenPrice().call()
        const value = numberOfTokens * price
        const transaction = await contract.methods.purchase(numberOfTokens).send({
          from: state.address,
          value
        })
        return transaction
      }
    },
    async purchaseAllowList({ state }, numberOfTokens) {
      if (!state.address) {
        return false
      } else {
        const contract = state.contract
        const price = await contract.methods.tokenPrice().call()
        const value = numberOfTokens * price
        const transaction = await contract.methods.purchaseAllowList(numberOfTokens).send({
          from: state.address,
          value
        })
        return transaction
      }
    },
    async onAllowList({ state }) {
      if (!state.address) {
        return false
      } else {
        const contract = state.contract
        const onAllowList = await contract.methods.onAllowList(state.address).call()
        return onAllowList
      }
    },
    async allowListClaimedBy({ state }) {
      const contract = state.contract
      const balance = await contract.methods.allowListClaimedBy(state.address).call()
      return balance
    },
    async publicClaimedBy({ state }) {
      const contract = state.contract
      const balance = await contract.methods.publicClaimedBy(state.address).call()
      return balance
    },
    async getBalanceOf({ state }) {
      if (!state.address) {
        return false
      } else {
        const contract = state.contract
        const balance = await contract.methods.balanceOf(state.address).call()
        return balance
      }
    },
    setAddress({ commit }, address) {
      commit('address', address)
    },
    setState({ commit }, payload) {
      commit(payload.name, payload.value)
    },
    setAlert({ commit }, message) {
      commit('alert', message)
    }
  },
  modules: {
  },
  plugins: [
    createPersistedState({
      key: 'KLWR',
      paths: ['address', 'cachedWallet'],
      fetchBeforeUse: true
    })
  ]
})

<template>
  <div id="loadingScreen" :class="{ 'is-shown': loading }">
    <div class="loading-animation">
      <div class="w-full text-center">
        <img :src="require('@/assets/img/loading-alt.gif')">
      </div>
      <div class="loading-text">
        Now Loading<span>.</span><span>.</span><span>.</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingScreen',
}
</script>

<style scoped lang="scss">
#loadingScreen {
  @apply fixed w-full h-full top-0 left-0 flex justify-center z-50 transition-opacity duration-300 opacity-0 pointer-events-none;
  background-color: #19b3c1;

  &.is-shown {
    @apply opacity-100 pointer-events-auto;
  }

  .loading-animation {
    @apply my-auto max-w-full flex flex-wrap justify-center italic;

    img {
      @apply w-32 mx-auto;
    }

    .loading-text {
      @apply font-comic mt-2;
      
      span {
        &:nth-of-type(1) {
          animation: 1.5s linear 0s infinite loadingDot1;
        }

        &:nth-of-type(2) {
          animation: 1.5s linear 0s infinite loadingDot2;
        }

        &:nth-of-type(3) {
          animation: 1.5s linear 0s infinite loadingDot3;
        }
      }
    }
  }
}

@keyframes loadingDot1 {
  0% {
    @apply opacity-0;
  }

  24% {
    @apply opacity-0;
  }

  25% {
    @apply opacity-100;
  }

  100% {
    @apply opacity-100;
  }
}

@keyframes loadingDot2 {
  0% {
    @apply opacity-0;
  }

  49% {
    @apply opacity-0;
  }

  50% {
    @apply opacity-100;
  }

  100% {
    @apply opacity-100;
  }
}

@keyframes loadingDot3 {
  0% {
    @apply opacity-0;
  }

  74% {
    @apply opacity-0;
  }

  75% {
    @apply opacity-100;
  }

  100% {
    @apply opacity-100;
  }
}
</style>

<template>
  <div id="roadmap" class="section">
    <div class="container">
      <h1 class="roadmap__title">ROADMAP</h1>
      <div class="roadmap">
        <div v-for="(phase, index) in phases" :key="index" :class="['phase', orderClass(index)]">
          <h1 class="phase__title" v-html="phase.title" />
          <div class="phase__content" v-html="phase.content" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { forEach } from 'lodash'
import roadmap from '../roadmap'

export default {
  name: 'Roadmap',
  data() {
    return {
      phases: roadmap.percentage
    }
  },
  beforeMount() {
    window.addEventListener('scroll', this.affixPhase)
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.affixPhase)
  },
  methods: {
    orderClass(index) {
      const classes = [
        'order-1',
        'lg:order-3',
        'lg:order-2',
        'order-4',
      ]
      return classes[index]
    },
    affixPhase() {
      const els = document.getElementsByClassName('roadmap__phase')
      const offset = window.innerHeight / 2
      forEach(els, (el) => {
        const elTop = (el.offsetTop - offset)
        const elBottom = (el.offsetHeight + el.offsetTop - offset)
        if (window.scrollY >= elTop && window.scrollY < elBottom) {
          el.classList.add('is-visible')
        } else {
          el.classList.remove('is-visible')
        }
      })
    }
  }
}
</script>

<style lang="scss">
#roadmap {
  @apply w-full py-16;
  background-color: #19b3c1;
}

.roadmap {
  @apply flex flex-wrap -mx-4;

  &__title {
    @apply text-4xl font-bold mb-12;
    text-shadow: -3px 3px 0 #b73030;
  }
}

.phase {
  @apply w-full lg:w-1/2 px-4 mb-12;

  &__title {
    @apply font-bold text-2xl lg:text-3xl uppercase mb-2;
    color: #364665;
  }

  &__content {
    h2 {
      @apply font-bold text-lg lg:text-xl mb-1;
    }

    ul {
      @apply list-disc pl-5;
    }
  }
}
</style>

<template>
  <div id="appHeader" ref="appHeader">
    <div class="container h-full">
      <div class="flex flex-wrap h-full items-center -mx-4">
        <div class="app-logo">
          <img :src="logo">
        </div>
        <nav class="app-nav">
          <a
            v-for="(nav, index) in navs"
            :class="{ active: activeNav === nav.id }"
            :key="index"
            :href="`#${nav.id}`"
            @click.prevent="slideTo(nav.id)"
            v-text="nav.label"
          />
          <div class="app-wallet px-4">
            <wallet-connector />
          </div>
        </nav>
        <button type="button" class="app-nav-toggler">
          <span v-for="n in 3" :key="n" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { forEach } from 'lodash'
import WalletConnector from './WalletConnector'

export default {
  name: 'AppHeader',
  components: {
    WalletConnector
  },
  beforeMount() {
    window.addEventListener('scroll', this.onScroll)
  },
  mounted() {
    this.onScroll()
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    onScroll() {
      if (window.scrollY >= 150) {
        this.$refs.appHeader.classList.add('window-scrolled')
      } else {
        this.$refs.appHeader.classList.remove('window-scrolled')
      }

      const sections = document.getElementsByClassName('section')
      const headerOffset = 56
      forEach(sections, (section) => {
        const elTop = (section.offsetTop - headerOffset)
        const elBottom = (section.offsetHeight + section.offsetTop - headerOffset)
        if (window.scrollY >= elTop && window.scrollY < elBottom){
          this.activeNav = section.id
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
#appHeader {
  @apply fixed top-0 left-0 w-full h-14 bg-transparent z-10;

  &::before {
    content: '';
    @apply absolute left-0 w-full pointer-events-none bg-black opacity-0 transition-all duration-500 ease-in-out;
    top: -100%;
    z-index: -1;
    height: 100px;
    background: linear-gradient(180deg, rgba(0,0,0,.75) 0%, rgba(0,0,0,.75) 5%, rgba(0,0,0,0) 100%);
  }

  &.window-scrolled::before {
    @apply top-0 opacity-100;
  }
}

.app-logo {
  @apply w-52 font-bold uppercase px-4;
}

.app-nav {
  @apply hidden flex-col justify-center flex-wrap items-center fixed inset-0 bg-black md:ml-auto;

  @screen md {
    @apply relative bg-transparent flex-row inset-auto flex;
  }

  a {
    @apply text-white px-3;

    &.active {
      @apply text-red-500;
    }
  }

  &-toggler {
    @apply md:hidden w-8 h-8 mx-4 focus:outline-none ml-auto;

    span {
      width: 100%;
      height: 2px;
      display: block;
      margin-bottom: 4px;
      background: #ffffff;
    }
  }
}

.app-tools {
  @apply flex items-center;

  a {
    @apply mx-2 w-6 h-6;
  }
}
</style>

<template>
  <div id="comic">
    <div ref="blurBg" class="blur-bg" />
    <swiper
      :slides-per-view="1"
      :space-between="50"
      :mousewheel="true"
      :speed="1000"
      direction="vertical"
      @activeIndexChange="onIndexActiveChange"
    >
      <swiper-slide>
        <div class="absolute w-full top-1/2 left-1/2 transform -translate-x-1/2  -translate-y-1/2 text-center p-4">
          <div class="px-4 max-w-lg mx-auto mb-4">
            <img :src="require('@/assets/img/kalawira-logo.png')">
          </div>
          <button type="button" @click="skipComic()" class="py-2 px-5 bg-red-600 text-white hover:bg-red-800 rounded-md">
            Continue to site <span class="text-lg">&Rarr;</span>
          </button>
        </div>
        <div class="absolute bottom-4 w-full text-center">
          <p>Scroll down to read story</p>
          <span class="text-3xl">&Darr;</span>
        </div>
      </swiper-slide>
      <swiper-slide v-for="(page, index) in pages" :key="index">
        <div class="comic-page">
          <div v-if="page.panels" class="comic-page__panel">
            <img
              :key="panel"
              v-for="panel in page.panels"
              class="comic-page__panel-image"
              :src="require(`@/assets/img/comic/page_${index+1}/panel_${panel}.png`)"
              :style="{ transitionDelay: 150 * panel + 'ms' }"
              @load="loadedPanels++"
            >
          </div>
          <div v-if="page.speech" class="comic-page__speech">
            <div
              :key="index"
              v-for="(bubble, index) in page.speech"
              class="comic-page__speech-bubble content"
              v-html="bubble.text"
              :style="bubbleStyle(bubble.style, index)"
            />
          </div>
        </div>
      </swiper-slide>

      <swiper-slide>
        <div class="w-full h-full flex items-center justify-center">
          <div class="text-center">
            <div class="px-4 max-w-full">
              <img :src="require('@/assets/img/kalawira-logo.png')">
            </div>
            <button type="button" @click="skipComic()" class="py-2 px-5 bg-red-600 text-white hover:bg-red-800 rounded-md">
              Claim your power <span class="text-lg">&Rarr;</span>
            </button>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Mousewheel } from 'swiper';
SwiperCore.use([Mousewheel]);
import 'swiper/swiper.min.css';

import { set, sumBy } from 'lodash'
import comic from '@/comic'

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      pages: comic.pages,
      loadedPanels: 0,
    }
  },
  watch: {
    loadedPanels(count) {
      const target = sumBy(this.pages, 'panels')
      if (count >= target)
        setTimeout(() => {
          this.setState({
            name: 'loading',
            value: false,
          })
        }, 2000)
    }
  },
  methods: {
    bubbleStyle(style, index) {
      const fixIndex = index + 1
      set(style, 'transitionDelay', 350 * fixIndex + 'ms')
      return style
    },
    skipComic() {
      this.setState({
        name: 'loading',
        value: true,
      })

      this.setState({
        name: 'comic',
        value: false,
      })
    },
    onIndexActiveChange(swiper) {
      if (swiper.activeIndex > 0) {
        this.$refs.blurBg.classList.add('go-brrr')
      } else {
        this.$refs.blurBg.classList.remove('go-brrr')
      }
    },
  }
}
</script>

<style lang="scss">
#comic {
  @apply relative w-full h-screen;

  .blur-bg {
    @apply fixed -inset-8 bg-cover bg-center bg-no-repeat transition-all duration-500 ease-in-out;
    will-change: filter;
    background-image: url('~@/assets/img/comic/blurred-mobile.jpg');

    &.go-brrr {
      @apply filter blur-lg;
    }

    @screen md {
      background-image: url('~@/assets/img/comic/blurred.jpg');
    }
  }

  .comic-page {
    @apply relative w-full h-full font-comic;

    &__speech {
      @apply absolute w-full h-full top-0 left-0 pointer-events-none;
      will-change: transform;
      perspective: 300px;

      &-bubble {
        @apply absolute w-auto max-w-xl text-sm md:text-base lg:text-lg xl:text-xl text-center py-5 px-6 border-4 border-black text-black bg-white rounded-lg;
        will-change: transform, opacity;
        transform: translateY(0);
        opacity: 1;
        transition: transform 1s cubic-bezier(0.5, 1, 0.89, 1), opacity 1s ease-in; 
      }
    }

    &__panel {
      @apply absolute w-full h-full top-0 left-0 pointer-events-none;

      &-image {
        @apply absolute top-0 left-0 w-full h-full object-contain;
        will-change: transform, opacity;
        perspective: 300px;
        transform: translateY(0), rotateX(0), rotateY(0);
        opacity: 1;
        transition: transform 1s cubic-bezier(0.5, 1, 0.89, 1), opacity 1s ease-in; 
      }
    }
  }

  .swiper {
    &-container {
      @apply h-screen;
    }
  
    &-wrapper {
      transition-property: transform;
      transition-timing-function: cubic-bezier(0.76, 0, 0.24, 1);
    }

    &-slide {
      &-next {
        .comic-page__speech-bubble,
        .comic-page__panel-image {
          opacity: 0;
          transform: translateY(3rem);
        }
      }

      &-prev {
        .comic-page__speech-bubble,
        .comic-page__panel-image {
          opacity: 0;
          transform: translateY(-3rem);
        }
      }
    }
  }
}
</style>

<template>
  <modal
    v-model="walletModal"
    :close="closeModal"
    @after-enter="afterEnter"
    @after-leave="afterLeave"
  >
    <div class="modal">
      <h2 class="text-lg font-bold mb-4 text-center">
        {{ !address ? 'Connect Wallet' : 'Disconnect Wallet' }}
      </h2>
      <div v-if="!address">
        <div class="wallet">
          <div>
            <button type="button" class="wallet__button" @click="doConnect('metamask')">
              <img :src="require('@/assets/img/wallet/metamask-logo.png')">
              <p>MetaMask</p>
            </button>
          </div>
          <div>
            <button type="button" class="wallet__button" @click="doConnect('walletconnect')">
              <img :src="require('@/assets/img/wallet/walletconnect-logo.png')">
              <p>WalletConnect</p>
            </button>
          </div>
        </div>
      </div>
      <div v-else>
        <button type="button" class="wallet__disconnect" @click="doDisconnect()">
          Disconnect
        </button>
      </div>
      <p class="text-center">
        New to Ethereum wallets?
        <a target="_blank" class="inline-flex items-center" rel="noopener noreferrer" href="https://ethereum.org/en/wallets/">
          Learn more
          <unicon name="external-link-alt" width="1rem" height="1rem" fill="currentColor" />
        </a>
      </p>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'WalletModal',
  methods: {
    closeModal() {
      this.setState({
        name: 'walletModal',
        value: false
      })
    },
    afterEnter () {
      document.documentElement.style.overflowY = 'hidden'
    },
    afterLeave () {
      document.documentElement.style.overflowY = 'auto'
    },
    doConnect(name) {
      console.log('connecting')
      this.connectWallet(name)
      this.closeModal()
    },
    doDisconnect() {
      this.disconnectWallet()
      this.closeModal()
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  @apply p-4 rounded-lg bg-gray-900 shadow-md max-w-full;
  width: 560px;
}

.wallet {
  @apply flex flex-wrap -mx-2;

  div {
    @apply w-full md:w-1/2 px-2;
  }

  &__button {
    @apply w-full bg-gray-800 hover:bg-gray-700 rounded-lg flex flex-col items-center pt-2 pb-4 px-4 mb-4;

    &:not(:last-child) {
      @apply mb-4;
    }

    img {
      @apply w-20;
    }

    p {
      @apply text-lg font-bold;
    }
  }

  &__disconnect {
    @apply w-full bg-gray-800 hover:bg-gray-700 rounded-lg flex flex-col items-center p-4 mb-4;
  }
}
</style>

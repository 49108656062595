<template>
  <div :class="['minter', { 'is-connected': address && isActive }]">
    <div class="minter__logo">
      <img :src="require('@/assets/img/kalawira-logo.png')">
    </div>
    <div v-if="!address">
      <button
        type="button"
        class="minter__connect-wallet"
        @click="setState({ name: 'walletModal', value: true })"
      >
        Connect Wallet
      </button>
    </div>
    <div v-else-if="contract && isActive">
      <div class="mb-4">
        <div class="text-xl">Kalawira MINTED</div>
        <div class="text-3xl font-bold mb-2">
          <span class="text-red-600">{{ tokenSupply }}</span> / {{ tokenMax }}
        </div>
        <div v-if="allowListOnly">You have minted {{ allowListClaimed }} / {{ allowListMaxMint }} allowlist max</div>
        <div v-else>You have minted {{ publicClaimed }} / {{ publicMaxMint }} public max</div>
      </div>
      <div class="mb-6">
        <div class="text-xl">Price</div>
        <div class="text-2xl font-bold">{{ tokenPrice / 10**18 }} ETH</div>
        <div class="text-sm italic">Excluding gas fee</div>
        <div v-if="allowListOnly" class="mt-4 text-sm italic">
          Only address on allowlist can mint
        </div>
      </div>
      <button
        v-if="!onLimit"
        type="button"
        :class="['minter__purchase', { processing }]"
        @click="processMint"
        :disabled="processing"
      >
        MINT
      </button>
      <div v-else class="minter__limit">
        LIMIT REACHED
      </div>
    </div>
  </div>
</template>

<script>
import { forEach } from 'lodash'

export default {
  name: 'Minter',
  data() {
    return {
      processing: false,
      tokenPrice: 0,
      isActive: false,
      allowListOnly: false,
      publicMaxMint: 0,
      allowListMaxMint: 0,
      tokenMax: 0,
      tokenSupply: 0,
      supplyPolling: null,
      allowListClaimed: 0,
      publicClaimed: 0,
    }
  },
  computed: {
    onLimit() {
      return (this.allowListOnly && this.allowListClaimed >= this.allowListMaxMint) || (this.publicClaimed >= this.publicMaxMint)
    }
  },
  watch: {
    contract(contract) {
      console.log(contract)
      if (contract) {
        this.getData()
      } else {
        this.resetData()
      }
    }
  },
  mounted() {
    if (this.cachedWallet) {
      console.log('connecting wallet')
      this.connectWallet(this.cachedWallet)
    }
  },
  methods: {
    resetData() {
      this.processing = false
      this.isActive = false
      this.allowListOnly = false
      this.publicMaxMint = 0
      this.allowListMaxMint = 0
      this.tokenMax = 0
      this.tokenSupply = 0
      this.allowListClaimed = 0
      this.publicClaimed = 0
      clearInterval(this.supplyPolling)
    },
    async getData() {
      const list = [
        'tokenPrice',
        'isActive',
        'allowListOnly',
        'publicMaxMint',
        'allowListMaxMint',
        'tokenMax'
      ]
      forEach(list, (name) => {
        this.getContractData(name)
          .then((response) => {
            this[name] = response
            if (name === 'isActive' && !response) {
              this.setState({
                name: 'alert',
                value: "Contract is currently inactive!"
              })
            }
          })
      })
      this.supplyPolling = setInterval(() => {
        this.getContractData('totalSupply')
          .then((response) => {
            this.tokenSupply = response
          })
      })
      this.publicClaimed = await this.publicClaimedBy()
      this.allowListClaimed = await this.allowListClaimedBy()
    },
    async processMint() {
      if (this.processing)
        return false
      
      // Start processing
      this.processing = true

      // Define alert messages
      const successMsg = "You get a piece of the stone. Feel the power yet?"
      const cancelledMsg = "Don't be scared of power. Come and get yours now!"
      const errorMsg = "Something really bad has happened. Don't you give up yet!"

      // Contract is active
      if (this.isActive) {
        console.log("Contract is active")

        if (this.allowListOnly) {
          console.log("Allow list only")
          const onAllowList = await this.onAllowList()

          // Address is onAllowList
          if (onAllowList) {
            console.log("On allow list")
            this.setAlert(successMsg)
            this.purchaseAllowList(1)
              .then(async () => {
                this.setAlert()
                this.publicClaimed = await this.publicClaimedBy()
              })
              .catch((error) => {
                const msg = error.code == 4001 ? cancelledMsg : errorMsg
                this.setAlert(msg)
              })
              .finally(() => {
                this.processing = false
              })
          } else {
            console.log("Not on allowlist")
            this.setAlert("Sorry, your address is not on our allowlist")
            this.processing = false
          }
        } else {
          console.log("Public mint")
          this.setAlert("Processing your transaction...")
          this.purchase(1)
            .then(async () => {
              this.setAlert(successMsg)
              this.publicClaimed = await this.publicClaimedBy()
            })
            .catch((error) => {
              const msg = error.code == 4001 ? cancelledMsg : errorMsg
              this.setAlert(msg)
            })
            .finally(() => {
              this.processing = false
            })
        }
      } else {
        this.setAlert("Oops, our contract is currently inactive")
        this.processing = false
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.minter {
  @apply max-w-full py-8 px-6;
  width: 480px;

  &.is-connected {
    @apply rounded-lg bg-black bg-opacity-80;
  }

  &__logo {
    @apply max-w-full mb-4 mx-auto;
  }

  &__connect-wallet {
    @apply text-lg py-2 px-8 rounded-full bg-red-600 hover:bg-red-800 focus:outline-none mx-auto flex flex-col items-center;
  }

  &__purchase {
    @apply py-2 px-4 rounded-md bg-red-600 hover:bg-red-800 focus:outline-none mx-auto;
    min-width: 225px;

    &.processing,
    &:disabled {
      @apply cursor-wait bg-red-500 hover:bg-red-500;
    }
  }

  &__limit {
    @apply inline-block py-2 px-4 rounded-md bg-gray-600 mx-auto cursor-not-allowed;
  }
}
</style>

<template>
  <comic v-if="comic" :class="{ 'opacity-0': loading }" />
  <div v-if="!comic" :class="{ 'opacity-0': loading }" id="main">
    <app-header />
    <home />
    <about />
    <rarities />
    <roadmap />
    <team />
    <faqs />
    <app-footer />
    <alert />
    <wallet-modal />
  </div>
  <loading />
</template>

<script>
import Loading from './components/Loading'
import Comic from './components/Comic'
import AppHeader from './components/AppHeader'
import AppFooter from './components/AppFooter'
import Home from './components/Home'
import About from './components/About'
import Rarities from './components/Rarities'
import Roadmap from './components/Roadmap'
import Faqs from './components/Faqs'
import Team from './components/Team'
import Alert from './components/Alert'
import WalletModal from '@/components/WalletModal'

export default {
  name: 'App',
  components: {
    Loading,
    Comic,
    AppHeader,
    AppFooter,
    Home,
    About,
    Rarities,
    Roadmap,
    Faqs,
    Team,
    Alert,
    WalletModal
  },
  watch: {
    loading(isLoading) {
      document.documentElement.style.overflowY = isLoading ? 'hidden' : 'auto'
    }
  },
  created() {
    document.title = "Kalawira"
  },
  mounted() {
    // if (window.ethereum) {
    //   window.ethereum.request({ method: 'eth_accounts' })
    //     .then((accounts) => {
    //       this.setState({
    //         name: 'account',
    //         value: accounts[0]
    //       })
    //       this.initContract()
    //         .then((exists) => {
    //           if (!exists)
    //             this.setState({
    //               name: 'alert',
    //               value: "Smart contract doesn't exists on current active network!"
    //             })
    //         })
    //     })

    //   window.ethereum.on('accountsChanged', this.handleAccountsChanged)
    //   window.ethereum.on('chainChanged', this.handleChainChanged)
    // }
  },
  // beforeUnmount() {
    // window.ethereum.removeListener('accountsChanged', this.handleAccountsChanged)
    // window.ethereum.removeListener('chainChanged', this.handleChainChanged)
  // },
  // methods: {
  //   handleAccountsChanged(accounts) {
  //     this.setState({
  //       name: 'account',
  //       value: accounts[0]
  //     })
  //   },
  //   handleChainChanged() {
  //     this.initContract()
  //       .then((exists) => {
  //         if (!exists)
  //           this.setState({
  //             name: 'alert',
  //             value: "Smart contract doesn't exists on current active network!"
  //           })
  //       })
  //   }
  // }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import './assets/fonts/animeace/stylesheet.css';
@import './assets/scss/app.scss';
</style>

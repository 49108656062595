export default {
  percentage: [
    {
      title: '25% Sold',
      content: `
        <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit</h2>
        <ul>
          <li>De braaaiiiins apocalypsi gorger omero</li>
          <li>Prefrontal cortex undead survivor</li>
          <li>Hi brains mindless mortuis limbic cortex</li>
        </ul>
      `,
      classes: 'order-1',
    },
    {
      title: '50% Sold',
      content: `
        <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit</h2>
        <ul>
          <li>De braaaiiiins apocalypsi gorger omero</li>
          <li>Prefrontal cortex undead survivor</li>
          <li>Hi brains mindless mortuis limbic cortex</li>
        </ul>
      `,
      classes: 'lg:order-3',
    },
    {
      title: '75% Sold',
      content: `
        <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit</h2>
        <ul>
          <li>De braaaiiiins apocalypsi gorger omero</li>
          <li>Prefrontal cortex undead survivor</li>
          <li>Hi brains mindless mortuis limbic cortex</li>
        </ul>
      `,
      classes: 'lg:order-2',
    },
    {
      title: '100% Sold',
      content: `
        <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit</h2>
        <ul>
          <li>De braaaiiiins apocalypsi gorger omero</li>
          <li>Prefrontal cortex undead survivor</li>
          <li>Hi brains mindless mortuis limbic cortex</li>
        </ul>
      `,
      classes: 'order-4',
    },
  ],
  additional: false
}

<template>
  <div id="appFooter">
    <div class="container">
      <div class="footer">
        <div class="footer-col footer-logo mr-auto">
          <img :src="logo" class="w-80 max-w-full">
        </div>
        <div class="footer-col md:text-right">
          <div class="text-red-500 text-sm mb-2">Menu</div>
          <ul>
            <li v-for="(nav, index) in navs" :key="index">
              <a
                :href="`#${nav.id}`"
                @click.prevent="slideTo(nav.id)"
                v-text="nav.label"
              />
            </li>
          </ul>
        </div>
        <div class="footer-col md:text-right">
          <div class="text-red-500 text-sm mb-2">Social</div>
          <ul>
            <li>
              <a href="https://twitter.com" target="_blank">Twitter</a>
            </li>
            <li>
              <a href="https://discord.gg" target="_blank">Discord</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="text-center text-sm text-red-500">
        Copyright &copy;2021 Kalawira. All rights reserved.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppFooter'
}
</script>

<style scoped lang="scss">
#appFooter {
  @apply bg-black pt-32 pb-12;
}

.footer {
  @apply flex flex-wrap -mx-4 mb-12;

  &-col {
    @apply w-1/2 lg:w-1/4 px-4 mb-8;

    &.footer-logo {
      @apply w-full lg:w-1/4
    }
  }
}
</style>

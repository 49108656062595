<template>
    <div id="team" class="section">
      <div class="container">
        <h1 class="title">Kalawira Team</h1>
        <div class="team">
          <div v-for="member in members" :key="member.id" class="member">
            <div class="member-photo" :style="{ backgroundColor: member.color, backgroundImage: `url(${require(`@/assets/img/new-team/team-${member.id}.png`)})`}" />
            <h2 class="text-xl font-bold" v-text="member.name" />
            <h4 v-text="member.position" />
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'Team',
  data() {
    return {
      members: [
        {
          id: 1,
          name: 'Sigmund',
          position: 'Project Manager',
          color: '#006E90',
        },
        {
          id: 2,
          name: 'Havardr',
          position: 'Community Relations',
          color: '#F18F01',
        },
        {
          id: 3,
          name: 'Verdandi',
          position: 'Artist',
          color: '#ADCAD6',
        },
        {
          id: 4,
          name: 'Ragnhild',
          position: 'Tech Development',
          color: '#99C24D',
        },
        {
          id: 5,
          name: 'Sigrid',
          position: 'Front-end Design',
          color: '#6DBF93',
        },
        {
          id: 6,
          name: 'Bjarni',
          position: 'In-house Illustrator',
          color: '#41BBD9',
        },
      ]
    }
  },
  methods: {
    tilt(event) {
      const rect = event.target.getBoundingClientRect()
      var x = event.clientX - rect.left; //x position within the element.
      var y = event.clientY - rect.top;  //y position within the element.
      console.log("Left? : " + x + " ; Top? : " + y + ".")
    }
  }
}
</script>

<style scoped lang="scss">
#team {
  @apply w-full py-32;
  background-color: #058dbf;
}

.title {
  @apply text-4xl font-bold mb-12 uppercase;
  text-shadow: -3px 3px 0 #b73030;
}

.team {
  @apply flex flex-wrap -mx-12;

  .member {
    @apply w-full md:w-1/3 px-12 mb-12 lg:mb-24 text-center;

    &-photo {
      @apply w-full overflow-hidden bg-cover bg-bottom mb-3 transition-all duration-100 ease-linear;
      padding-top: 100%;
    }
  }
}
</style>
